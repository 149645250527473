import React from 'react';
import Layout from '../layouts/Layout';
import CTA from '../components/CTA/CTA';
import JobAlerts from '../components/JobAlerts/JobAlerts';
import arrow from '../images/arrow.svg';
import logoIcon from '../images/logo-icon.png';
import smallBanner from "../images/small-banner.jpg";
import arrowRight from "../images/arrow-right-w.svg";
import storageVault from "../images/storage-vault-lofo.svg";
import accessLogo from "../images/access-storage.svg";
import sentinelLogo from "../images/sentinelonblack.png";
import depotiumLogo from "../images/depotium-logo.svg";
import cubeit from "../images/cubet-it-logo.svg";
import storageVaultLogo from "../images/storage-vault-lofo.svg";
import recordXLogo from "../images/record-express-logo.svg";
import flexSpaceLogo from "../images/flex-space-logo.svg";
import moveBuddyLogo from "../images/movebuddy-logo.svg";
import accessResultManagementServices from "../images/ARMSLogo.png"
const JobDescription = ({ pageContext }: any) => {
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		}
	];
	const mapLogo = {
		'Access Storage': accessLogo,
		'Access Storage/ Safe Self Storage': accessLogo,
		'Sentinel Storage': sentinelLogo,
		Sentinel: sentinelLogo,
		Depotium: depotiumLogo,
		Cubeit: cubeit,
		'Storage Vault': storageVaultLogo,
		RecordXpress: recordXLogo,
		'FlexSpace Logistics': flexSpaceLogo,
		'Storage Vault Canada': storageVaultLogo,
		'Move Buddy': moveBuddyLogo,
		'ARMS': accessResultManagementServices

	};
	const url = new URL(pageContext.url);
	url.searchParams.set('source', 'talent');
	return (
		<Layout
			menuItems={menuItems}
			title={'Storage Vault Careers'}
			desc={'Storage Vault Careers'}
		>
			<section
				style={{
					backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 6e-05) 1.55%, rgba(0, 0, 0, 0.6) 100%), url('${smallBanner}')`
				}}
				className={
					'flex min-h-[265px] w-full flex-row items-center bg-cover bg-top'
				}
			>
				<div
					className={
						'container mx-auto flex h-full flex-col justify-between px-5'
					}
				>
					<a
						className={
							'mb-6 mt-6     flex w-48 flex-row rounded-full border border-white px-6 py-2.5 text-white hover:text-white hover:opacity-90'
						}
						href={'/search'}
					>
						<img src={arrowRight} className={'mr-4 rotate-180'} />
						Back to Search
					</a>
					<div className={'flex flex-col'}>
						<h1
							className={
								'font-sand pt-8 text-[38px] font-bold text-white'
							}
						>
							{pageContext.title}
						</h1>
						<span
							className={'mb-8 mt-3 font-sans text-xl text-white'}
						>
							{pageContext.city}, {pageContext.province}
						</span>
					</div>
				</div>
			</section>
			<section>
				<div className={'container mx-auto my-12 flex flex-col-reverse lg:flex-row px-5'}>
					<div className={'flex w-full lg:w-3/4 flex-col px-6'}>
						<p
							dangerouslySetInnerHTML={{
								__html: pageContext.description
							}}
						/>
					</div>
					<div className={'flex w-full max-lg:mb-12 lg:w-1/4 flex-col'}>
						<div className={'w-full bg-[#F0F4F8] p-5'}>
							<img className={'mb-6'} src={mapLogo[pageContext.brand]} />
							<span className={'mb-10 font-sans text-body text-xl'}>{pageContext.title}</span>
							<p className={'font-sans mt-3'}>{pageContext.city}, {pageContext.province}</p>
							<a href={url.href} className={''}><button
								className={
									'ApplyButton ApplyButtonTop mx-auto text-center flex my-6 mt-10 pl-12 rounded-full bg-pri py-3 px-10 w-48 mt-6 font-sans text-lg text-white hover:opacity-90 focus:opacity-90'
								}
							>
								Apply Now
							</button></a>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
